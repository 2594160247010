<template>
  <div :class="$style.careerProfile">
    <div :class="$style.careerProfileHeading">
      <template v-if="isLoggedIn && firstName">
        <div :class="$style.careerProfileTitle">
          <span>
            {{ $awt('aw.common.career.profile.welcome', { name: firstName }) }} 👋
          </span>
        </div>
        <div :class="$style.careerProfileTitleSecondary">
          {{ $awt('aw.common.career.profile.welcome_secondary') }}
        </div>
      </template>
      <div v-else :class="$style.careerProfileTitle">
        {{ $awt('aw.common.career.profile.title') }}
      </div>
    </div>
    <div :class="$style.careerProfileBodyFooterWrapper">
      <div v-if="hasCareerProfile" :class="$style.careerProfileBody">
        <div :class="$style.careerProfileBodyContent">
          <div :class="$style.careerProfileBodyIcon">
            <div :class="$style.careerProfileBodyIconInner">
              <lv-icon name="business-16" :size="16" />
            </div>
          </div>
          <div :class="$style.careerProfileBodyText">
            <span v-html="$awt('aw.common.career.profile.job_applications', { count: applicationCount })" />
          </div>
        </div>
        <div :class="$style.careerProfileBodyContent">
          <div
            :class="[$style.careerProfileBodyIcon, {[$style.careerProfileBodyIconNotification]: waitingApplicationCount > 0 }]"
          >
            <div :class="$style.careerProfileBodyIconInner">
              <lv-icon name="handshake-16" :size="16" />
            </div>
          </div>
          <div :class="$style.careerProfileBodyText">
            <span v-html="$awt('aw.common.career.profile.offers_pending', { count: waitingApplicationCount })" />
          </div>
        </div>
      </div>
      <div
        :class="[$style.careerProfileFooter, { [$style.careerProfileFooterMarginLeft]: hasCareerProfile, [$style.careerProfileFooterLogin]: !hasCareerProfile }]"
      >
        <lv-button
          v-if="hasCareerProfile"
          styling="link-primary-version2"
          shrink
          type="button"
          :class="$style.careerProfileFooterLinkButton"
          @click="clickLogout()"
        >
          <span v-html="$awt('aw.common.career.profile.logout')" />
        </lv-button>
        <template v-else-if="isLoggedIn && !hasCareerProfile">
          <lv-button
            styling="link-primary-version2"
            shrink
            type="button"
            :class="$style.careerProfileFooterLinkButton"
            @click="openCareerRegistrationModal({ isEcomUserExtended: true })"
          >
            <span v-html="$awt('aw.common.career.profile.create_account')" />
          </lv-button>

          <aw-separator margin-bottom="search" margin-top="search" />
          <div :class="$style.careerProfileTitle">
            {{ $awt('aw.common.career.profile.title') }}
          </div>
        </template>
        <template v-if="!hasCareerProfile">
          <aw-form v-model="v$" :class="$style.loginFormWrapper" @success="careerLogin">
            <div :class="$style.loginForm">
              <div
                v-for="({ field, error }, key) in validators"
                :key="key"
                :class="[
                  $style.loginFormItem,
                  $style[`loginFormItem${key}`],
                ]"
              >
                <aw-input-version2
                  v-bind="field"
                  :type="pwdIconTypes[key]"
                  :has-label="Boolean(floatingLabels[key])"
                  :floating-label-text="floatingLabels[key] || ''"
                  :widget-attrs="{ id: `${componentId}_${key}`}"
                  :widget-icon-attrs="pwdIconAttrs[key]"
                />
                <aw-minimal-error v-bind="error" :class="$style.careerProfileFormError">
                  <template #err_userExistsError>
                    <div :class="$style.userExistsError">
                      <span v-text="userExistsError.message" />
                      <lv-button
                        styling="link-primary-version2"
                        :class="[$style.userExistsErrorLogin, 'awFocusV']"
                        @click="openLoginModal"
                      >
                        {{ $awt('aw.common.career.profile.user_exists_error_login') }}
                      </lv-button>
                    </div>
                  </template>
                </aw-minimal-error>
                <lv-button
                  v-if="key === PASSWORD"
                  styling="link-primary-version2"
                  shrink
                  type="button"
                  :class="[
                    $style.careerProfileFooterLinkButton,
                    'awFocusV',
                  ]"
                  :to="localePath('forgotten-password')"
                >
                  {{ $awt('aw.common.career.profile.forgotten_password') }}
                </lv-button>
              </div>
              <div aria-live="assertive" :class="$style.loginFormItemApiError">
                <aw-notification-item
                  v-if="apiError"
                  :notification="apiError"
                  :thin-content="true"
                  index="0"
                  is-margin-less
                />
              </div>
            </div>
            <aw-separator margin-bottom="search" margin-top="search" />
            <div :class="$style.careerProfileFooterLoginButtonRow">
              <client-only>
                <aw-form-button
                  v-model="v$"
                  styling="career-menu-login"
                  :loading-id="['career-login-modal', LOGGED_OUT_TOASTER_CAREER_LOGIN]"
                  :class="$style.careerProfileFooterLoginButton"
                >
                  {{ $awt('aw.common.career.profile.login') }}
                </aw-form-button>
              </client-only>

              <div v-if="!isLoggedIn" :class="$style.careerProfileFooterRegister">
                <span :class="$style.careerProfileFooterText">
                  {{ $awt('aw.common.career.profile.no_account') }}
                </span>
                <lv-button
                  styling="link-primary-version2"
                  shrink
                  :class="[$style.careerProfileFooterLinkButton, 'awFocusV']"
                  @click="openCareerRegistrationModal({ isEcomUserExtended: false })"
                >
                  {{ $awt('aw.common.career.profile.no_account_link') }}
                </lv-button>
              </div>
            </div>
          </aw-form>
        </template>
      </div>
    </div>

    <aw-career-account-registration-modal v-if="modalType === M_CAREER_ACCOUNT_REGISTRATION" />
  </div>
</template>

<script setup>
  import { useVuelidate } from '@vuelidate/core';
  import { watch, onMounted, ref, defineAsyncComponent } from 'vue';
  import { useId, useNuxtApp, useRuntimeConfig } from 'nuxt/app';
  import { M_CAREER_ACCOUNT_REGISTRATION } from '~~/common/config/Modal.js';
  import { setErrorNotification, getErrorNotification } from '~~/common/utils/helper.js';
  import { awEmail, awRequired } from '~~/common/utils/form.js';
  import { generatePwdIcons } from '~~/common/utils/pwdFormUtils.js';
  import { camelToSnakeCase } from '~~/common/utils/index.js';
  import { useUserStore } from '~~/common/stores/user.js';
  import { useModalStore } from '~~/common/stores/modal.js';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface.js';
  import { useAuthenticationStore } from '~~/common/stores/authentication.js';
  import { useDeliveryStore } from '~~/shop/stores/delivery.js';
  import { clickLogout } from '~~/common/mixins/orderDataMixin.js';
  import { openLoginModal } from '~~/common/mixins/userLoginAndRegistrationMixin.js';
  import { LvButton, LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwForm from '~~/common/components/Form/AwForm.vue';
  import AwFormButton from '~~/common/components/Form/AwFormButton.vue';
  import AwInputVersion2, { awInputV2Props } from '~~/common/components/Common/AwInputVersion2.vue';
  import AwCareerAccountRegistrationModal
    from '~~/common/components/Common/Modals/CareerAccountRegistrationModal/AwCareerAccountRegistrationModal.vue';
  import AwMinimalError from '~~/common/components/Common/AwMinimalError.vue';
  import AwSeparator from '~~/common/components/Common/AwSeparator.vue';
  import { LOGGED_OUT_TOASTER_CAREER_LOGIN } from '~~/common/config/LoadingIdConfig.js';
  import { useToasterStore } from '~~/common/stores/toaster.js';

  const AwNotificationItem = defineAsyncComponent(() => import('~~/common/components/Common/AwNotificationItem.vue'));

  const userStore = useUserStore();
  const componentId = useId();
  const nuxtApp = useNuxtApp();
  const {
    $awt,
    $logger,
  } = nuxtApp;

  const EMAIL = 'email';
  const PASSWORD = 'password';

  const { pwdIconTypes, pwdIconAttrs } = generatePwdIcons([PASSWORD]);

  onMounted(() => {
    if (hasCareerProfile.value) {
      userStore.fetchCareerStatus();
    }
  });

  const isLoggedIn = computed(() => {
    return !userStore.isAnonUser;
  });
  const isEcomUser = computed(() => {
    return userStore.isEcomUser;
  });
  const firstName = computed(() => {
    const personalInfo = userStore.data?.personalInfo;
    return personalInfo?.careerInfo?.givenName || personalInfo?.firstName || '';
  });
  const hasCareerProfile = computed(() => {
    return !!userStore.data?.personalInfo?.career;
    // NOTE: mindenhol máshol ezt nézzük, itt miért mást csinálunk?
    // !!userStore.data?.personalInfo?.careerPersonalInfo?.careereExternalId;
  });
  const applicationCount = computed(() => {
    return userStore.data.careerStatus?.applicationCount ?? 0;
  });
  const waitingApplicationCount = computed(() => {
    return userStore.data.careerStatus?.waitingApplicationCount ?? 0;
  });
  const modalType = computed(() => {
    return useModalStore().activeGenericModal.type;
  });

  watch(hasCareerProfile, (newValue) => {
    if (newValue) {
      userStore.fetchCareerStatus();
    }
  });

  const userExistsError = ref(null);
  const apiError = ref(null);

  const models = {
    [EMAIL]: ref(''),
    [PASSWORD]: ref(''),
  };

  const v$ = useVuelidate({
    [EMAIL]: {
      required: awRequired(),
      email: awEmail(),
      userExistsError (emailValue) {
        return !userExistsError.value?.email || (emailValue !== userExistsError.value?.email);
      },

    },
    [PASSWORD]: {
      required: awRequired(),
    },
  }, models);

  const validators = computed(() => {
    const result = {};
    [
      EMAIL,
      PASSWORD,
    ].forEach(key => {
      result[key] = awInputV2Props({
        validator: v$.value,
        validatorName: key,
        model: models[key],
        id: `${componentId}_${key}_error`,
      });
    });
    return result;
  });

  const labels = computed(() => {
    return Object.fromEntries(
      (Object.keys(validators.value)
        .map((k) => {
          return [k, $awt(`aw.career_profile.login_label.${camelToSnakeCase(k)}`)];
        })
      ),
    );
  });
  const floatingLabels = computed(() => {
    return labels.value;
  });

  function openCareerRegistrationModal ({ isEcomUserExtended }) {
    useModalStore().showCareerRegistrationModal({
      modalType: M_CAREER_ACCOUNT_REGISTRATION,
      isEcomUserExtended,
    });
  }

  async function careerLogin () {
    userExistsError.value = null;
    apiError.value = null;
    const userInterfaceStore = useUserInterfaceStore();
    const userStore = useUserStore();
    const authenticationStore = useAuthenticationStore();
    const deliveryStore = useDeliveryStore();
    const toasterStore = useToasterStore();

    const loginToCareerProfile = async () => {
      try {
        userInterfaceStore.startLoading({ id: 'career-login-modal' });

        const loginData = {
          username: models.email.value,
          password: models.password.value,
        };
        await authenticationStore.loginWithCareerUsernameAndPassword(loginData);
        await userStore.fetchUser();
        await deliveryStore.fetchMethod();
        await deliveryStore.fetchTimeframes();

        v$.value.$reset();
      } catch (error) {
        $logger.error(error);
        if (error?.response?.data?.scope === 'auchan_account_exist') {
          userExistsError.value = {
            email: models.email.value,
            message: error.response.data.message,
          };
        } else if (error?.response?.data?.scope === 'inactive_user') {
          onShowInactiveCustomerModel();
        } else if (error?.response?.data?.scope === 'login') {
          apiError.value = getErrorNotification(nuxtApp, error);
        } else {
          setErrorNotification({ nuxtApp, error });
        }
      } finally {
        userInterfaceStore.endLoading({ id: 'career-login-modal' });
      }
    };

    if (isEcomUser.value) {
      userInterfaceStore.startLoading({ id: LOGGED_OUT_TOASTER_CAREER_LOGIN });
      const logout = authenticationStore.logout();
      toasterStore.push({
        title: $awt('aw.career.toast.loggged_out_from_auchan_profile'),
        closeAfter: useRuntimeConfig().public.careerToasterTimeoutMs,
        callback: async () => {
          await logout;
          await loginToCareerProfile();
          userInterfaceStore.endLoading({ id: LOGGED_OUT_TOASTER_CAREER_LOGIN });
        },
      });
    } else {
      await loginToCareerProfile();
    }

  }

  function onShowInactiveCustomerModel () {
    const {
      addQueueSafeConfirmModal,
      // removeQueueSafeConfirmModal,
    } = useModalStore();
    addQueueSafeConfirmModal({
      uuid: componentId,
      data: {
        closable: true,
        data: {
          confirmTitle: $awt('aw.career.inactive_user_modal.title'),
          confirmText: $awt('aw.career.inactive_user_modal.description'),
          buttons: {
            ok: {
              text: $awt('aw.career.inactive_user_modal.button.ok'),
            },
          },
        },
      },
    });
  }
</script>

<style module lang="scss" rel="stylesheet/scss">
.careerProfile {
  padding: 16px;
  border-radius: 12px;
  background-color: $color-background-3;

  &Heading {
    margin-bottom: -1px;
    padding-bottom: 20px;
    border-bottom: 1px dashed $color-dash-separator;

    @include tablet(min) {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    @include sidebar-desktop-medium-large(min) {
      display: block;
    }
  }

  &Title {
    font-size: 14px;
    font-weight: $font-weight-bold-v2;
    line-height: 20px;

    @include tablet(min) {
      font-size: 16px;
      line-height: 24px;
    }

    &Secondary {
      font-size: 10px;
      font-weight: 500;
      line-height: 16px;
      margin-top: 8px;
      color: $color-text-secondary;

      @include tablet(min) {
        font-size: 12px;
        margin-top: 0;
      }

      @include sidebar-desktop-medium-large(min) {
        margin-top: 8px;
      }
    }
  }

  &Body {
    display: grid;
    flex-direction: row;
    width: 100%;
    margin-bottom: -1px;
    padding: 20px 0;
    border-bottom: 1px dashed $color-dash-separator;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    @include tablet(min) {
      display: inline-flex;
      flex-direction: row;
      width: unset;
      padding-bottom: 0;
      border-bottom: unset;
      gap: 20px;
    }

    @include sidebar-desktop-medium-large(min) {
      display: flex;
      flex-direction: column;
      max-width: unset;
      margin-bottom: -1px;
      margin-left: unset;
      padding-bottom: 20px;
      border-bottom: 1px dashed $color-dash-separator;
      gap: 12px;
    }

    &Content {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 8px;
      row-gap: 12px;
      align-items: center;
    }

    &Icon {
      position: relative;
      padding: 1.5px 0;

      @include tablet(min) {
        padding: 4px 0;
      }

      &Inner {
        line-height: 16px;
        display: flex;
        align-items: center;
        height: 32px;
        padding: 7px;
        border: 1px solid $color-border-disabled;
        border-radius: 8px;
        background-color: $color-background-4;
      }

      &Notification {
        &::before {
          position: absolute;
          top: 4px;
          right: 0;
          width: 8px;
          height: 8px;
          content: "";
          border-radius: 50%;
          outline: 2px solid $color-background-4;
          background-color: $color-strawberry-450;
        }
      }
    }

    &Text {
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;

      @include tablet(min) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &Footer {
    padding-top: 20px;

    &MarginLeft {
      display: flex;
      @include tablet(min) {
        margin-left: 20px;
      }

      @include sidebar-desktop-medium-large(min) {
        margin-left: unset;
      }
    }

    @include tablet(min) {
      display: inline-block;
    }

    @include sidebar-desktop-medium-large(min) {
      display: block;
    }

    &Login {
      display: block;
      width: 100%;
      @include tablet(min) {
        max-width: unset;
      }


      &ButtonRow {
        display: flex;
        gap: 8px;
        flex-flow: column;

        @include tablet(min) {
          align-items: center;
          flex-flow: row;
          gap: 16px;
        }


        @include sidebar-desktop-medium-large(min) {
          gap: 8px;
          flex-flow: column;
        }
      }

      .careerProfileTitle {
        margin-bottom: 20px;
      }
    }

    &Register {
      display: inline-block;
    }

    &LinkButton {
      font-size: 12px;
      line-height: 16px;
      display: inline-block;
      text-decoration: underline;

      @include tablet(min) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &LoginButton {
      width: max-content;
    }

    &Text {
      font-size: 12px;
      line-height: 16px;
      margin-right: 3px;

      @include tablet(min) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .loginForm {
      display: flex;
      flex-direction: column;
      gap: 8px;

      @include tablet(min) {
        @include sidebar-desktop-medium-large(below) {
          display: grid;
          gap: 8px 16px;
          grid-template-columns: 1fr 1fr;
          grid-template-areas: "email password"  "apiError password";
          grid-template-rows: max-content 1fr;
        }
      }

      &Wrapper {
        width: 100%;
      }

      &Item {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .careerProfileFormError {
          margin-top: -8px;
        }

        .careerProfileFormError:empty {
          position: absolute;
        }


        [data-input-block] {
          width: 100%;
          max-width: 100%;
        }

        .userExistsError {
          display: flex;
          flex-flow: column;

          &Login {
            max-width: max-content;
            padding: 0;
            text-decoration: underline;
            color: $color-error-v2-text;
            @include font($font-weight-medium-v2, 14px, 20px);

            @include sidebar-desktop-medium-large(min) {
              margin-left: 0;
            }
          }
        }

        &email {
          grid-area: email;
        }

        &password {
          grid-area: password;
        }

        &ApiError {
          grid-area: apiError;
        }
      }
    }
  }

  &BodyFooterWrapper {
    display: flex;
    align-items: start;
    flex-flow: column;

    @include tablet(min) {
      align-items: center;
      flex-flow: row;
    }

    @include sidebar-desktop-medium-large(min) {
      align-items: start;
      flex-flow: column;
    }
  }
}
</style>
