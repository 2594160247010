<template>
  <div :class="$style.dataCol">
    <div :class="$style.dataRow">
      <div :class="$style.dataName">
        {{ $awt('aw.bandd.email') }}
      </div>
      <div :class="$style.dataValue">
        {{ personalInfo.email }}
      </div>
    </div>
    <div :class="$style.dataRow">
      <div :class="$style.dataName">
        {{ $awt('aw.bandd.last_name') }}
      </div>
      <div :class="$style.dataValue">
        {{ personalInfo.lastName }}
      </div>
    </div>
    <div :class="$style.dataRow">
      <div :class="$style.dataName">
        {{ $awt('aw.bandd.first_name') }}
      </div>
      <div :class="$style.dataValue">
        {{ personalInfo.firstName }}
      </div>
    </div>
    <div :class="$style.dataRow">
      <div :class="$style.dataName">
        {{ $awt('aw.bandd.birth_date') }}
      </div>
      <div :class="$style.dataValue">
        {{ displayedBirthDate }}
      </div>
    </div>
    <div :class="$style.dataRow">
      <div :class="$style.dataName">
        {{ $awt('aw.bandd.phone_number') }}
      </div>
      <div :class="$style.dataValue">
        {{ phoneNumber }}
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useUserStore } from '~~/common/stores/user.js';
  import { removeTZInfoFromDateStr } from '~~/common/utils/index.js';
  import { useNuxtApp } from 'nuxt/app';

  const personalInfo = computed(() => {
    return useUserStore().data.personalInfo;
  });

  const displayedBirthDate = computed(() => {
    const { $date } = useNuxtApp();
    return personalInfo.value.birthDate
      ? $date(removeTZInfoFromDateStr(personalInfo.value.birthDate), {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      })
      : '';
  });

  const phoneNumber = computed(() => {
    return personalInfo.value?.phoneNumber && personalInfo.value.phoneNumber.length > 6 ? personalInfo.value.phoneNumber : '';
  });

</script>

<style module lang="scss" rel="stylesheet/scss">
.data {
  &Col {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &Row {
    display: grid;
    grid-template-columns: 1fr;
    @include tablet(min) {
      column-gap: 24px;
      grid-template-columns: 200px 1fr;
    }
    @include desktop-small(only) {
      column-gap: 32px;
    }
    @include desktop-medium(min) {
      column-gap: 40px;
    }
  }

  &Name {
    font-size: 14px;
    font-weight: $font-weight-normal-v2;
    line-height: 20px;
    @include tablet(min) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &Value {
    font-size: 14px;
    font-weight: $font-weight-bold-v2;
    line-height: 20px;
    @include tablet(min) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>
