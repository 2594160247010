<template>
  <aw-cms-page-sidebar-wrapper
    :menu-items="menuItems"
    :notification="notifications"
    :selected-item="currentItem"
    @navigate="clickOnCareerMenu"
  >
    <template #overSidebar>
      <aw-career-profile />
    </template>
    <template #content>
      <slot />
    </template>
  </aw-cms-page-sidebar-wrapper>
</template>

<script>
  import { useRoute } from 'nuxt/app';
  import { mapState } from 'pinia';
  import { useContentMenusStore } from '~~/common/stores/contentMenus.js';
  import { removeLocaleFromUrl } from '~~/common/utils/urlTransformation.js';
  import { useBreadcrumbStore } from '~~/common/stores/breadcrumb.js';
  import AwCareerProfile from '~~/common/components/AwCareerProfile.vue';
  import AwCmsPageSidebarWrapper from '~~/common/components/Page/Content/Wrapper/Sidebar/AwCmsPageSidebarWrapper.vue';
  import { useUserStore } from '~~/common/stores/user.js';

  const CAREER_PROFILE_MENU = '/karrier/fiokom';

  export default {
    name: 'AwCareerMenu',
    components: {
      AwCmsPageSidebarWrapper,
      AwCareerProfile,
    },
    computed: {
      ...mapState(useContentMenusStore, {
        careerMenuItems: state => state.careerMenu,
      }),
      ...mapState(useUserStore, {
        careerStatus: state => state.data.careerStatus,
        careerPersonalInfo: state => state.data?.personalInfo?.careerPersonalInfo,
      }),
      notifications () {
        return this.menuItems.filter((menuItem) => {
          return menuItem.link === CAREER_PROFILE_MENU && (this.careerStatus?.waitingApplicationCount ?? 0) > 0;
        }).map(notification => notification.id);
      },
      menuItems () {
        return this.careerMenuItems.map((item, index) => ({
          id: `${item.to}-${index}`,
          name: item.label,
          link: item.to,
        })).filter(menuItem => menuItem.link !== CAREER_PROFILE_MENU || this.careerPersonalInfo?.careereExternalId);
      },
      currentItem () {
        return this.menuItems.find(item => this.isActive(item));
      },
    },
    async created () {
      const contentMenuStore = useContentMenusStore();
      await contentMenuStore.initMenu('career-menu');
      const breadCrumbStore = useBreadcrumbStore();
      const careerHome = contentMenuStore.careerMenu[0];
      const careerHomeToken = this.$awt('aw.pages.career.title');
      if (!careerHome) {
        return;
      }
      breadCrumbStore.setBreadcrumbs(breadCrumbStore.breadcrumbs.map((breadcrumb) => {
        if (breadcrumb.token === careerHomeToken) {
          breadcrumb.to = careerHome.to;
        }
        return breadcrumb;
      }));
    },
    methods: {
      removeTrailingSlash (url) {
        return url.replace(/\/$/, '');
      },
      isActive (menuItem) {
        const route = useRoute();
        return this.removeTrailingSlash(removeLocaleFromUrl(route.path)) === this.removeTrailingSlash(menuItem.link);
      },
      async clickOnCareerMenu (menuItem) {
        const breadcrumbStore = useBreadcrumbStore();
        const contentMenuStore = useContentMenusStore();
        const careerHome = contentMenuStore.careerMenu[0];

        breadcrumbStore.setBreadcrumbs([
          {
            token: this.$awt('aw.common.category.home'),
            to: '/',
            disabled: false,
          },
          {
            token: this.$awt('aw.pages.career.title'),
            to: careerHome?.to ?? '/karrier',
            disabled: true,
          },
          {
            token: menuItem.label,
            to: menuItem.to,
            disabled: true,
          },
        ]);
      },
    },
  };
</script>
