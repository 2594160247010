<template>
  <aw-modal
    :opened="isOpened"
    size="md"
    :modal-title="$awt('aw.common.career.career_registration_modal.title')"
    @close-modal="hideModal"
  >
    <template #content>
      <aw-form v-model="v$" :class="$style.modal">
        <div v-if="!isEcomUserExtended" :class="$style.careerProfileReminder">
          <span
            v-text="$awt(
              'aw.common.career.career_registration_modal.form_reminder.description_not_ecom'
            )"
          />
          <lv-button
            href="javascript:void(0)"
            styling="link-primary-version2"
            :class="['awFocusV', $style.careerProfileLoginAsEcom]"
            @click="switchToLoginModal"
          >
            {{ $awt('aw.common.career.career_registration_modal.form_reminder.btn_not_ecom') }}
          </lv-button>
        </div>
        <div aria-live="assertive">
          <aw-notification-item
            v-if="apiError"
            :notification="apiError"
            :thin-content="true"
            index="0"
          />
        </div>
        <div
          :class="[
            $style.modalProfileTitleContainer,
            $style.modalSectionTitleContainer
          ]"
        >
          <h4 :class="$style.modalSectionTitle">
            {{ $awt('aw.common.career.career_registration_modal.sections.profile.title') }}
          </h4>
          <template v-if="isEcomUserExtended">
            <lv-button
              v-if="!isEditingProfile"
              styling="small-secondary-dark-just-icon"
              :disabled="isLoading"
              :aria-label="$awt('aw.common.career.career_registration_modal.edit_profile_btn')"
              :title="$awt('aw.common.career.career_registration_modal.edit_profile_btn')"
              @click="isEditingProfile = true"
            >
              <lv-icon :size="16" name="pencil-version2-16" style="vertical-align: unset;" />
            </lv-button>
            <lv-button
              v-else
              styling="small-primary-dark"
              shrink
              loading-id="career-account-create-profile-form-save"
              :class="$style.tabletMin"
              @click="saveUserProfileData"
            >
              {{ $awt('aw.common.career.career_registration_modal.sections.profile.save_changes') }}
            </lv-button>
          </template>
        </div>
        <aw-career-account-registration-profile-data v-if="!isEditingProfile && isEcomUserExtended" />
        <div v-else ref="editUserDataFormWrapper" :class="$style.dataRow">
          <template v-if="isEcomUserExtended">
            <div :class="[$style.dataName, $style.dataNameDisabled]" v-text="labels.email" />
            <div :class="[$style.dataFieldGenericInput, $style.dataFieldPrefilled]" v-text="email" />
          </template>
          <label
            :for="lastNameObj.id"
            :class="[$style.dataName, $style.tabletMin]"
            v-text="labels.lastName"
          />
          <div>
            <div :class="$style.dataFieldGenericInput">
              <aw-input-version2 v-bind="lastNameObj.bind" />
            </div>
            <aw-minimal-error v-bind="lastNameObj.bindErr" />
          </div>
          <label
            :for="firstNameObj.id"
            :class="[$style.dataName, $style.tabletMin]"
            v-text="labels.firstName"
          />
          <div>
            <div :class="$style.dataFieldGenericInput">
              <aw-input-version2 v-bind="firstNameObj.bind" />
            </div>
            <aw-minimal-error v-bind="firstNameObj.bindErr" />
          </div>
          <label
            :for="birthDateObj.id"
            :class="[$style.dataName, $style.tabletMin]"
            v-text="labels.birthDate"
          />
          <div>
            <div :class="$style.dataFieldDateInput">
              <aw-date-picker
                v-bind="birthDateObj.bind"
                :widget-attrs="{
                  ...birthDateObj.bind.widgetAttrs,
                  disabled: isEcomUserExtended && !!birthDate,
                }"
                :config="{
                  maxDate: defaultBirthDate,
                }"
              />
            </div>
            <aw-minimal-error v-bind="birthDateObj.bindErr" />
          </div>
          <template v-if="isEcomUserExtended">
            <label
              :for="phoneNumberObj.id"
              :class="[$style.dataName, {awSrOnlyAbs: !showFormLabel}]"
              v-text="labels.phoneNumber"
            />
            <div>
              <div :class="$style.dataFieldPhoneNumberInput">
                <aw-input-version2
                  v-bind="phoneNumberObj.bind"
                  :floating-label-classes="[$style.mobileFloatingLabel]"
                >
                  <template v-if="prefixes[phoneNumberObj.key]" #prefix>
                    {{ prefixes[phoneNumberObj.key] }}
                  </template>
                </aw-input-version2>
              </div>
              <div v-bind="phoneNumberObj.bindHelp" :class="$style.labelAfter" v-text="helperMsgs[phoneNumberObj.key]" />
              <aw-minimal-error v-bind="phoneNumberObj.bindErr" />
            </div>
            <div :class="$style.mobileMax">
              <lv-button
                styling="small-primary-dark"
                shrink
                loading-id="career-account-create-profile-form-save"
                @click="saveUserProfileData"
              >
                {{ $awt('aw.common.career.career_registration_modal.sections.profile.save_changes') }}
              </lv-button>
            </div>
          </template>
        </div>
        <div :class="$style.sectionDivider" />
        <div :class="$style.modalSectionTitleContainer">
          <h4 :class="$style.modalSectionTitle">
            {{ $awt('aw.common.career.career_registration_modal.sections.applicant_data.title') }}
          </h4>
        </div>
        <div :class="$style.dataRow">
          <label
            :for="educationLevelObj.id"
            :class="[$style.dataName, $style.tabletMin]"
            v-text="labels.educationLevel"
          />
          <div :class="[$style.dataFieldGenericInput, $style.dataFieldGenericInputEducationLevel]">
            <div>
              <aw-select-version2
                v-bind="educationLevelObj.bind"
                :inline-label-text="floatingLabels.educationLevel"
                :options="possibleEducationLevels"
                :multiple="false"
                option-id-property="id"
                option-label-property="name"
                :dropdown-icon-attrs="{
                  name: 'arrow-down-16',
                  size: 16
                }"
              />
              <aw-minimal-error v-bind="educationLevelObj.bindErr" />
            </div>
          </div>
          <label
            :for="linkedInObj.id"
            :class="[$style.dataName, $style.tabletMin]"
            v-text="labels.linkedIn"
          />
          <div>
            <div :class="[$style.dataFieldGenericInput, $style.dataFieldGenericInputLinkedIn]">
              <aw-input-version2 v-bind="linkedInObj.bind" />
            </div>
            <aw-minimal-error v-bind="linkedInObj.bindErr" />
          </div>
          <label
            :for="fileCvObj.id"
            :class="[$style.dataName, $style.dataNameCv]"
            v-text="labels.fileCv"
          />
          <div>
            <div :class="$style.dataField">
              <aw-file-input
                v-bind="fileCvObj.bind"
                :is-uploading="isUploading"
                :max-file-size="20"
                :tokens="{
                  fileUploadLabelForMobile: $awt('aw.file_uploader.upload_cv_label'),
                }"
              />
            </div>
          </div>
        </div>
        <template v-if="!isEcomUserExtended">
          <div :class="$style.sectionDivider" />
          <div :class="$style.modalSectionTitleContainer">
            <h4 :class="$style.modalSectionTitle">
              {{ $awt('aw.common.career.career_registration_modal.sections.contact.title') }}
            </h4>
          </div>
          <div :class="$style.dataRow">
            <label
              :for="emailObj.id"
              :class="[$style.dataName, $style.tabletMin]"
              v-text="labels.email"
            />
            <div>
              <div :class="[$style.dataFieldGenericInput, $style.dataFieldGenericInputEmail]">
                <aw-input-version2 v-bind="emailObj.bind" type="email" />
              </div>
              <aw-minimal-error v-bind="emailObj.bindErr">
                <template #err_systemMailVerification>
                  <div>
                    {{ systemMailVerification?.msg }}
                  </div>
                  <lv-button
                    v-if="systemMailVerification?.emailStatus !== 'error_format'"
                    styling="link-primary-version2"
                    shrink
                    class="awFocusV"
                    @click="switchToLoginModal"
                  >
                    <span :class="$style.errorBtn">
                      {{ $awt('aw.common.career.career_registration_modal.email_validation.login_btn') }}
                    </span>
                  </lv-button>
                </template>
              </aw-minimal-error>
            </div>
            <label
              :for="phoneNumberObj.id"
              :class="[$style.dataName, {awSrOnlyAbs: !showFormLabel}]"
              v-text="labels.phoneNumber"
            />
            <div>
              <div :class="$style.dataFieldPhoneNumberInput">
                <aw-input-version2
                  v-bind="phoneNumberObj.bind"
                  :floating-label-classes="[$style.mobileFloatingLabel]"
                >
                  <template v-if="prefixes[phoneNumberObj.key]" #prefix>
                    {{ prefixes[phoneNumberObj.key] }}
                  </template>
                </aw-input-version2>
              </div>
              <div v-bind="phoneNumberObj.bindHelp" :class="$style.labelAfter" v-text="helperMsgs[phoneNumberObj.key]" />
              <aw-minimal-error v-bind="phoneNumberObj.bindErr" />
            </div>
          </div>
          <div :class="$style.sectionDivider" />
          <div :class="$style.modalSectionTitleContainer">
            <h4 :class="$style.modalSectionTitle">
              {{ $awt('aw.common.career.career_registration_modal.sections.password.title') }}
            </h4>
          </div>
          <div :class="$style.dataRow">
            <template v-for="pwdObj in [passwordObj, passwordConfirmObj]" :key="pwdObj.key">
              <label
                :for="pwdObj.id"
                :class="[$style.dataName, $style.tabletMin]"
                v-text="labels[pwdObj.key]"
              />
              <div>
                <div :class="$style.dataFieldGenericInput">
                  <aw-input-version2
                    v-bind="pwdObj.bind"
                    :type="pwdIconTypes[pwdObj.key]"
                    :widget-icon-attrs="pwdIconAttrs[pwdObj.key]"
                  />
                </div>
                <aw-minimal-error v-bind="pwdObj.bindErr" />
              </div>
            </template>
          </div>
        </template>
        <div :class="$style.sectionDivider" />
        <div :class="$style.modalSectionTitleContainer">
          <h4 :class="$style.modalSectionTitle">
            {{ $awt('aw.common.career.career_registration_modal.sections.agreements.title') }}
          </h4>
        </div>
        <div :class="$style.dataRow">
          <div :class="[$style.dataName, $style.dataNameCheckbox]" />
          <div :class="$style.dataFieldCheckboxInput">
            <aw-form-row-version2 v-model="state.dataAgreement" />
          </div>
        </div>
        <div :class="$style.dataRow">
          <div :class="[$style.dataName, $style.dataNameCheckbox]" />
          <div :class="$style.dataFieldCheckboxInput">
            <aw-form-row-version2 v-model="state.applicationAgreement" />
          </div>
        </div>
        <div :class="$style.modalFooter">
          <lv-button
            loading-id="create-career-account-submit"
            type="submit"
            styling="primary-dark"
            shrink
            @click="submitCareerRegistration"
          >
            {{ $awt('aw.common.career.career_registration_modal.confirm') }}
          </lv-button>
          <lv-button
            styling="secondary-dark"
            shrink
            :disabled="isLoading"
            @click="hideModal"
          >
            {{ $awt('aw.common.career.career_registration_modal.cancel') }}
          </lv-button>
        </div>
      </aw-form>
    </template>
  </aw-modal>
</template>

<script setup>
  import { useNuxtApp, useId } from 'nuxt/app';
  import { ref, onMounted, reactive, watch, toRefs, nextTick, computed, defineAsyncComponent } from 'vue';
  import { useVuelidate } from '@vuelidate/core';
  import { helpers, required } from '@vuelidate/validators';
  import { useToasterStore } from '~~/common/stores/toaster';
  import { useUserStore } from '~~/common/stores/user.js';
  import { useCareerStore } from '~~/common/stores/career';
  import { useModalStore } from '~~/common/stores/modal.js';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface.js';
  import { F_EDUCATION_LEVEL } from '~~/common/config/CareerFilter.js';
  import { M_CAREER_ACCOUNT_REGISTRATION } from '~~/common/config/Modal.js';
  import { removeTZInfoFromDateStr } from '~~/common/utils/index.js';
  import { awRequired, awEmail, awPasswordConfirm, phonePrefix, awValidOptionalMobile, awIdNotEqualWith } from '~~/common/utils/form.js';
  import { generatePwdIcons } from '~~/common/utils/pwdFormUtils.js';
  import { backendValidator, getErrorNotification, setValidationFromError } from '~~/common/utils/helper.js';
  import { openLoginModal } from '~~/common/mixins/userLoginAndRegistrationMixin.js';
  import { LvButton, LvIcon } from '~~/common/components/loginet-vue-shop/index.mjs';
  import AwModal from '~~/common/components/Common/AwModal.vue';
  import AwForm from '~~/common/components/Form/AwForm.vue';
  import AwFormRowVersion2 from '~~/common/components/Form/AwFormRowVersion2.vue';
  import AwCareerAccountRegistrationProfileData from '~~/common/components/Common/Modals/CareerAccountRegistrationModal/AwCareerAccountRegistrationProfileData.vue';
  import AwInputVersion2, { awInputV2Props } from '~~/common/components/Common/AwInputVersion2.vue';
  import AwSelectVersion2, { awSelectV2Props } from '~~/common/components/Common/AwSelectVersion2.vue';
  import AwDatePicker, { awDatePickerProps } from '~~/common/components/Common/DatePicker/AwDatePicker.vue';
  import AwFileInput, { awFileProps } from '~~/common/components/Common/AwFileInput.vue';
  import AwMinimalError from '~~/common/components/Common/AwMinimalError.vue';
  const AwNotificationItem = defineAsyncComponent(() => import('~~/common/components/Common/AwNotificationItem.vue'));

  const {
    $awt,
    $api,
    $logger,
  } = useNuxtApp();
  const careerStore = useCareerStore();
  const userInterfaceStore = useUserInterfaceStore();
  const userStore = useUserStore();

  const isMounted = ref(false);
  const isEditingProfile = ref(false);
  const apiError = ref(false);
  const isLoading = ref(false);
  const clearFormTimeout = ref(0);

  const fileCv = ref(null);
  const isUploading = ref(false);
  const editUserDataFormWrapper = ref(null);

  const isCvRequired = computed(() => {
    return careerStore.isCvRequired();
  });

  const systemMailVerification = ref(null);

  const isOpened = computed(() => {
    return useModalStore().activeGenericModal.type === M_CAREER_ACCOUNT_REGISTRATION;
  });
  const isEcomUserExtended = computed(() => {
    return !!useModalStore().activeGenericModal?.item?.data?.isEcomUserExtended;
  });
  const screenRange = computed(() => {
    return userInterfaceStore.mediaQueries;
  });
  const showFormLabel = computed(() => {
    return isMounted.value && screenRange.value['tablet-min'];
  });
  const agreementsLabelSize = computed(() => {
    return screenRange.value['tablet-min'] ? 'lg' : 'md';
  });

  const defaultBirthDate = new Date();
  defaultBirthDate.setFullYear(defaultBirthDate.getFullYear() - 18);

  const { data: possibleFilters } = (await careerStore.fetchCareerAdvertisementFiltersSelectForm());
  const educationLevelsWithDefault = computed(() => {
    return possibleFilters.value?.[F_EDUCATION_LEVEL]?.values || {};
  });
  const possibleEducationLevels = computed(() => {
    return Object.values(educationLevelsWithDefault.value).slice(1);
  });

  const componentId = useId();
  const lastName = ref('');
  const firstName = ref('');
  const birthDate = ref(formatDate(defaultBirthDate));
  const linkedIn = ref('');
  const email = ref('');
  const phoneNumber = ref('');
  const password = ref('');
  const passwordConfirm = ref('');
  const educationLevel = ref({
    ...educationLevelsWithDefault.value[0],
    name: computed(() => (showFormLabel.value
      ? ''
      : educationLevelsWithDefault.value[0]?.name
    )),
  });

  const labels = computed(() => ({
    lastName: $awt('aw.bandd.last_name'),
    firstName: $awt('aw.bandd.first_name'),
    birthDate: $awt('aw.bandd.birth_date'),
    educationLevel: $awt('aw.bandd.educational_level'),
    linkedIn: $awt('aw.bandd.linkedin_profile'),
    dataAgreement: $awt('aw.common.career.career_registration_modal.sections.agreements.data_agreement'),
    applicationAgreement: $awt('aw.common.career.career_registration_modal.sections.agreements.application_agreement'),
    fileCv: $awt('aw.bandd.cv'),
    phoneNumber: $awt(isEcomUserExtended.value
      ? 'aw.bandd.phone_number'
      : 'aw.common.career.career_registration_modal.sections.contact.mobile_phone_number',
    ),
    email: $awt(isEcomUserExtended.value
      ? 'aw.bandd.email'
      : 'aw.common.career.career_registration_modal.sections.contact.email',
    ),
    password: $awt('aw.common.career.career_registration_modal.sections.password.password'),
    passwordConfirm: $awt('aw.common.career.career_registration_modal.sections.password.password_confirm'),
  }));
  const { pwdIconTypes, pwdIconAttrs } = generatePwdIcons(['password', 'passwordConfirm']);
  const floatingLabels = computed(() => {
    return ((isMounted.value && screenRange.value['mobile-max'])
      ? labels.value
      : {}
    );
  });

  const state = reactive({
    lastName,
    firstName,
    birthDate,
    email,
    password,
    passwordConfirm,
    phoneNumber,
    educationLevel,
    linkedIn,
    fileCv,
    dataAgreement: {
      model: false,
      type: 'checkbox',
      name: 'dataAgreement',
      widgetProps: {
        label: labels.value.dataAgreement,
        alignItems: 'start',
        showLabelInDesktop: true,
        labelSize: 'md',
      },
      version2: true,
      widgetAttrs: {
        id: 'dataAgreement',
      },
      formAttrs: {
        hideErrors: true,
      },
    },
    applicationAgreement: {
      model: false,
      type: 'checkbox',
      name: 'applicationAgreement',
      widgetProps: {
        label: labels.value.applicationAgreement,
        alignItems: 'start',
        showLabelInDesktop: true,
        labelSize: 'md',
      },
      version2: true,
      widgetAttrs: {
        id: 'applicationAgreement',
      },
      formAttrs: {
        hideErrors: true,
      },
    },
  });

  const backendValidation = reactive({
    errors: {},
    propertyPaths: {
      lastName: 'lastName',
      firstName: 'firstName',
      birthDate: 'birthDate',
      phoneNumber: 'phoneNumber',
      educationLevel: 'educationLevel',
      linkedIn: 'linkedIn',
      dataAgreement: 'dataAgreement',
      applicationAgreement: 'applicationAgreement',
    },
  });

  const formVm = reactive({
    ...toRefs(state),
    backendValidation,
  });

  const requiredIfEcomUser = awRequired({
    ifFun () {
      return isEcomUserExtended.value;
    },
  });

  const requiredIfNotEcomUser = awRequired({
    ifFun () {
      return !isEcomUserExtended.value;
    },
  });

  const rules = computed(() => {
    return {
      email: {
        required: awRequired(),
        email: awEmail(),
        systemMailVerification: helpers.withMessage(
          showMsgIfInvalid(systemMailVerification.value?.msg),
          (val) => {
            const verification = systemMailVerification.value;
            return !verification?.email || verification.email !== val;
          },
        ),
      },
      password: {
        required: requiredIfNotEcomUser,
      },
      passwordConfirm: {
        required: requiredIfNotEcomUser,
        awPasswordConfirm: awPasswordConfirm((val) => {
          return val === password.value;
        }),
      },
      lastName: {
        required: awRequired(),
        backend: backendValidator(formVm),
      },
      firstName: {
        required: awRequired(),
        backend: backendValidator(formVm),
      },
      birthDate: {
        required: awRequired(),
        isValid: helpers.withMessage(
          showMsgIfInvalid($awt('aw.common.form.date_not_valid')),
          userInterfaceStore.isValidDate,
        ),
        isOver18: helpers.withMessage(
          showMsgIfInvalid($awt('aw.common.form.over18_error_msg')),
          userInterfaceStore.isOver18,
        ),
      },
      phoneNumber: {
        required: requiredIfEcomUser,
        awValidOptionalMobile: awValidOptionalMobile(),
        backend: backendValidator(formVm),
      },
      educationLevel: {
        awIdNotEqualWith: awIdNotEqualWith(0),
        backend: backendValidator(formVm),
      },
      linkedIn: {
        backend: backendValidator(formVm),
      },
      fileCv: {
        required: awRequired({
          ifFun () {
            return isCvRequired.value;
          },
        }),
      },
      dataAgreement: {
        model: {
          required,
          backend: backendValidator(formVm),
          isAccepted (value) {
            return Boolean(value);
          },
        },
      },
      applicationAgreement: {
        model: {
          required,
          backend: backendValidator(formVm),
          isAccepted (value) {
            return Boolean(value);
          },
        },
      },
    };
  });

  const prefixes = computed(() => ({ phoneNumber: phonePrefix.value }));
  const helperMsgs = computed(() => ({ phoneNumber: $awt('aw.bandd.phone_number_example') }));

  const v$ = useVuelidate(rules, state);
  const lastNameObj = computed(() => createFormElement('text', lastName, 'lastName'));
  const firstNameObj = computed(() => createFormElement('text', firstName, 'firstName'));
  const birthDateObj = computed(() => createFormElement('date', birthDate, 'birthDate'));
  const linkedInObj = computed(() => createFormElement('text', linkedIn, 'linkedIn'));
  const emailObj = computed(() => createFormElement('text', email, 'email'));
  const phoneNumberObj = computed(() => createFormElement('text', phoneNumber, 'phoneNumber'));
  const passwordObj = computed(() => createFormElement('text', password, 'password'));
  const passwordConfirmObj = computed(() => createFormElement('text', passwordConfirm, 'passwordConfirm'));
  const educationLevelObj = computed(() => createFormElement('select', educationLevel, 'educationLevel'));
  const fileCvObj = computed(() => createFormElement('file', fileCv, 'fileCv'));

  const userData = computed(() => {
    return userStore.data;
  });

  const isAnonUser = computed(() => {
    return !!userStore.isAnonUser;
  });

  const formattedPhone = computed(() => (phoneNumber.value
    ? `${prefixes.value.phoneNumber}${phoneNumber.value}`
    : ''
  ));

  const formattedDate = computed(() => formatDate(birthDate.value));

  const isProfileDataFormChanged = computed(() => {
    const { personalInfo } = userData.value;
    return (
      lastName.value !== personalInfo.lastName ||
      firstName.value !== personalInfo.firstName ||
      formattedPhone.value !== personalInfo.phoneNumber
    );
  });

  onMounted(() => {
    isMounted.value = true;
    initForm();
  });

  watch(() => backendValidation.errors, (newValue) => {
    clearTimeout(clearFormTimeout.value);
    if (Object.keys(newValue).length) {
      clearFormTimeout.value = setTimeout(() => {
        v$.value.$reset();
        backendValidation.errors = {};
      }, 3000);
    }
  }, { deep: true });

  watch(agreementsLabelSize, (newValue) => {
    state.applicationAgreement.widgetProps.labelSize = newValue;
    state.dataAgreement.widgetProps.labelSize = newValue;
  });

  function initForm () {
    if (isAnonUser.value) {
      return;
    }
    const personalInfo = userData.value?.personalInfo || {};
    email.value = personalInfo.email;
    lastName.value = personalInfo.lastName ?? '';
    firstName.value = personalInfo.firstName ?? '';
    birthDate.value = personalInfo.birthDate ? removeTZInfoFromDateStr(personalInfo.birthDate) : '';
    phoneNumber.value = userInterfaceStore.str2Phone(personalInfo.phoneNumber ?? '', true).slice(-2).join('');
  }

  function hideModal () {
    useModalStore().hideCareerRegistrationModal({ modalType: M_CAREER_ACCOUNT_REGISTRATION });
  }

  async function saveUserProfileData () {
    try {
      if (!isProfileDataFormChanged.value) {
        apiError.value = false;
        isEditingProfile.value = false;
        return true;
      }
      if (!isProfileDataFormValid()) {
        return false;
      }
      userInterfaceStore.startLoading({ id: 'career-account-create-profile-form-save' });
      isLoading.value = true;

      await $api.$put('/me/personal_info', {
        email: email.value,
        firstName: firstName.value,
        lastName: lastName.value,
        phoneNumber: formattedPhone.value,
        birthDate: formattedDate.value,
      });
      await userStore.fetchUser();

      apiError.value = false;
      isEditingProfile.value = false;

      return true;
    } catch (error) {
      setValidationFromError(formVm, error, useNuxtApp());
      apiError.value = getErrorNotification(useNuxtApp(), error);
      return false;
    } finally {
      userInterfaceStore.endLoading({ id: 'career-account-create-profile-form-save' });
      isLoading.value = false;
    }
  }

  async function registerUserCareerProfile () {
    try {
      if (!isFormValid()) {
        isEditingProfile.value = true;
        nextTick(() => {
          editUserDataFormWrapper.value.scrollIntoView();
        });
        return [false, {}];
      }
      userInterfaceStore.startLoading({ id: 'career-account-create-profile-form-save' });
      isLoading.value = true;
      const newCareerData = {
        email: email.value,
        birthday: formattedDate.value,
        phone: formattedPhone.value,
        education: parseInt(educationLevel.value.id),
        linkedInURL: linkedIn.value,
        givenName: firstName.value,
        familyName: lastName.value,
      };
      const { cvUploadHash } = await $api.$post('/career/user', (isEcomUserExtended.value
        ? newCareerData
        : {
          ...newCareerData,
          credential: {
            username: email.value,
            password: password.value,
            confirmPassword: passwordConfirm.value,
          },
        }
      ));

      apiError.value = false;
      isEditingProfile.value = false;

      return [true, { cvUploadHash }];
    } catch (error) {
      setValidationFromError(formVm, error, useNuxtApp());
      apiError.value = getErrorNotification(useNuxtApp(), error);
      return [false, {}];
    } finally {
      userInterfaceStore.endLoading({ id: 'career-account-create-profile-form-save' });
      isLoading.value = false;
    }
  }

  async function uploadCv ({ cvUploadHash } = {}) {
    if (fileCv.value && (fileCv.value instanceof File)) {
      const [isSuccess] = await careerStore.pushCareerCv({ file: fileCv.value, cvUploadHash });
      return isSuccess;
    }
    return !isCvRequired.value;
  }

  async function submitCareerRegistration () {
    if (isLoading.value) {
      return;
    }
    try {
      const toasterStore = useToasterStore();
      userInterfaceStore.startLoading(({ id: 'create-career-account-submit' }));
      isUploading.value = true;
      if (!isEcomUserExtended.value) {
        if (!isFormValid()) {
          return;
        }
        const isMailCheckSuccessfull = await checkMail();
        if (!isMailCheckSuccessfull) {
          return;
        }
      } else if (isEditingProfile.value) {
        const isUserProfileDataSavedSuccessfully = await saveUserProfileData();
        if (!isUserProfileDataSavedSuccessfully) {
          return;
        }
      }
      const [isCareerProfileRegisteredSuccessfully, { cvUploadHash }] = await registerUserCareerProfile();
      if (!isCareerProfileRegisteredSuccessfully) {
        return;
      }
      const isCvUploadedSuccessfully = await uploadCv({ cvUploadHash });
      toasterStore.push({
        title: $awt('aw.common.career.career_registration_modal.success_feedback.title'),
        text: isEcomUserExtended.value ? '' : $awt('aw.common.career.career_registration_modal.success_feedback.desc'),
        manualClose: false,
      });
      if (!isCvUploadedSuccessfully) {
        toasterStore.push({
          title: $awt('aw.common.career.career_registration_modal.cv_upload.failed.title'),
          text: $awt('aw.common.career.career_registration_modal.cv_upload.failed.desc'),
          manualClose: false,
        });
      }
      userStore.fetchUser();
      hideModal();
    } catch (err) {
      $logger.error(err);
      userStore.fetchUser();
    } finally {
      isUploading.value = false;
      userInterfaceStore.endLoading(({ id: 'create-career-account-submit' }));
    }
  }
  async function checkMail () {
    const errorDetails = new Set((await $api.$post('/me/check_email', {
      email: email.value,
    }))?.errorDetails || []);
    const emailStatus = [
      'error_format',
      'auchan_account_exist',
      'career_user_exist',
    ].find((detail) => {
      return errorDetails.has(detail);
    });
    if (emailStatus) {
      systemMailVerification.value = {
        emailStatus,
        email: email.value,
        msg: $awt(`aw.common.career.career_registration_modal.email_validation.detail.${emailStatus}.desc`),
      };
    }
    return !emailStatus;
  }
  function isProfileDataFormValid () {
    v$.value.lastName.$touch();
    v$.value.firstName.$touch();
    v$.value.birthDate.$touch();
    v$.value.phoneNumber.$touch();
    return !v$.value.lastName.$invalid && !v$.value.firstName.$invalid && !v$.value.birthDate.$invalid && !v$.value.phoneNumber.$invalid;
  }

  function createFormElement (type, model, key) {
    const id = `${componentId}_${key}`;
    const validator = ({
      date: awDatePickerProps,
      text: awInputV2Props,
      select: awSelectV2Props,
      file: awFileProps,
    }[type])({
      validator: v$.value,
      validatorName: key,
      model,
      id: `${id}_error`,
    });
    const helperMsg = (helperMsgs.value[key]
      ? {
        ariaDescribedby: `${id}_help`,
      }
      : null
    );
    return {
      id,
      key,
      bind: {
        ...validator.field,
        hasLabel: Boolean(floatingLabels.value[key]),
        floatingLabelText: floatingLabels.value[key] || '',
        widgetAttrs: {
          ...helperMsg,
          id,
        },
      },
      bindHelp: {
        id: helperMsg?.ariaDescribedby,
      },
      bindErr: validator.error,
    };
  }

  function showMsgIfInvalid (msg) {
    return ({ $invalid }) => ($invalid ? msg : '');
  }

  function isFormValid () {
    v$.value.$touch();
    return !v$.value.$invalid;
  }

  function switchToLoginModal ($event) {
    openLoginModal($event);
    hideModal();
  }

  function formatDate (originalDate) {
    // initial the birthDate is ISOString and don't have toISOString function
    return (originalDate.toISOString
      ? `${originalDate.getFullYear()}-${originalDate.getMonth().toString().padStart(2, 0)}-${originalDate.getDate().toString().padStart(2, 0)}`
      : removeTZInfoFromDateStr(originalDate)
    );
  }
</script>

<style module lang="scss" rel="stylesheet/scss">
.careerProfileLoginAsEcom {
  display: inline;
  margin-left: 4px;
  vertical-align: baseline;
  text-decoration: underline;
}

.careerProfileReminder {
  padding: 20px 0;
  border-bottom: 1px dashed $color-dash-separator;
}

.modal {
  border-top: 1px dashed $color-dash-separator;

  &Profile {
    &TitleContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 20px;

      @include tablet(min) {
        padding-top: 40px;
      }
    }
  }

  &Section {
    &Title {
      font-size: 16px;
      font-weight: $font-weight-bold-v2;
      line-height: 20px;

      @include tablet(min) {
        font-size: 18px;
        line-height: 24px;
      }

      &Container {
        margin-bottom: 20px;
      }
    }
  }

  &Footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    padding-top: 16px;
    border-top: 1px dashed $color-dash-separator;
    gap: 16px;

    @include tablet(min) {
      flex-direction: row;
      margin-top: 40px;
      padding-top: 40px;
    }
  }
}

.data {
  &Table {
    display: flex;
    flex-direction: column;
  }

  &Row {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 8px;
    row-gap: 20px;
    @include tablet(min) {
      column-gap: 24px;
      grid-template-columns: 200px minmax(0, 1fr);
    }
    @include desktop-small(only) {
      column-gap: 32px;
    }
    @include desktop-medium(min) {
      column-gap: 40px;
    }
  }

  &Name {
    font-size: 14px;
    font-weight: $font-weight-normal-v2;
    line-height: 20px;
    display: flex;
    align-items: flex-start;
    padding-top: 14px;

    @include mobile(max) {
      // offset gap on mobile
      margin-bottom: -20px;
    }

    @include tablet(min) {
      font-size: 16px;
      line-height: 24px;
    }

    &Checkbox {
      padding-top: 0;
      @include mobile(max) {
        display: none;
      }
    }

    &Disabled {
      padding-top: 0;
    }

    &Cv {
      @include mobile(max) {
        padding-top: 0;
        padding-bottom: 8px;
      }
    }
  }

  &Field {
    &GenericInput {
      max-width: 200px;
    }

    &Prefilled {
      font-size: 14px;
      font-weight: $font-weight-bold-v2;
      line-height: 20px;
      margin-bottom: 20px;

      @include tablet(min) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &DateInput {
      position: relative;
      max-width: 200px;
    }

    &PhoneNumberInput {
      max-width: 248px;
    }
  }
}

.sectionDivider {
  width: 100%;
  margin: 20px 0;
  border-bottom: 1px dashed $color-dash-separator;

  @include tablet(min) {
    margin: 40px 0;
  }
}

.dataFieldGenericInput {
  &LinkedIn {
    width: 200px;
    max-width: 100%;

    @include desktop-small(min) {
      width: 344px;
    }
  }

  &Email {
    max-width: 296px;
  }

  &EducationLevel {
    width: 200px;
    max-width: 100%;

    @include desktop-small(min) {
      width: 296px;
    }
    @include tablet(min) {
      [data-form-input-select] {
        height: 52px;
      }
    }
  }
}

.tabletMin {
  @include mobile(max) {
    display: none;
  }
}

.mobileMax {
  @include tablet(min) {
    display: none;
  }
}

.mobileFloatingLabel {
  padding-left: 54px;
}

.labelAfter {
  @include font(500, 14px, 20px);
  font-style: italic;
  margin-top: 4px;
  margin-left: 16px;
  color: $color-text-secondary;
}

.errorBtn {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-decoration: underline;
  color: $color-error-v2-text;
}
</style>
